.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.formGroup {
    margin-bottom: 15px;
}

.label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
}

.input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;

    &:focus {
        outline: none;
        border-color: #007bff;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
    }
}
.button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
  
    &:hover {
      background-color: #0056b3;
    }
  
    &:focus {
      outline: none;
      box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
    }
  }
  
.infoContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.infoBlock {
    background-color: #ffffff;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 48%;
}

.infoTitle {
    font-size: 18px;
    margin-bottom: 5px;
    color: #333;
}

.infoText {
    font-size: 16px;
    color: #555;
}

.historyContainer {
    overflow-x: scroll;
    margin-top: 30px;
}

.historyTitle {
    font-size: 20px;
    margin-bottom: 10px;
    color: #333;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.table th,
.table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.table th {
    background-color: #007bff;
    color: white;
}

.table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.table tr:hover {
    background-color: #ddd;
}